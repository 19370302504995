    var EsqueciPasswordView = (function(m){
        /**
         * @param {EsqueciPasswordController} ctrl
         * @param args
         */
        var init = function(ctrl, args) {
            return m('div', [
                m('p.cabecalho-cor'),
                m('div#wrapper', [
                    m('header', [
                        m('div', [
                            m('a', {href:'/editar'}, [m('h1', 'Editor de Serviços')])
                        ])
                    ]),
                    m('form#login', {action:"", method:'get'}, [
                        m('h2', 'Esqueceu sua senha?'),
                        ctrl.renderSucesso(),
                        ctrl.renderEmailErrado(),
                        m('div.borda', [
                            m('label', {'for':'cpf'}, 'CPF'),
                            m('input#cpf', {
                                oninput: m.withAttr('value', ctrl.esqueciPasswordModel.cpf),
                                value: ctrl.esqueciPasswordModel.cpf(),
                                name:'cpf',
                                type:'text',
                                placeholder:'CPF',
                                required:'required',
                                autofocus:'true'
                            }),
                            m('label', {'for':'email'}, 'EMAIL'),
                            m('input#email', {
                                oninput: m.withAttr('value', ctrl.esqueciPasswordModel.email),
                                value: ctrl.esqueciPasswordModel.email(),
                                name:'email',
                                type:'text',
                                placeholder:'E-Mail',
                                required:'required',
                                autofocus:'true'
                            }),

                            m('p', [m('div#tentativas-email.g-recaptcha')]),

                            ctrl.renderSpinningButtonComponnent(),

                            m('button', {
                                onsubmit:ctrl.goHome,
                                onclick:ctrl.goHome,
                                style:'margin-right: 1em;'
                            }, 'Cancelar')
                        ])
                    ])
                ])
            ]);
        };

        return init;
    })(m);

    module.exports = EsqueciPasswordView;