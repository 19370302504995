    /**
     * @class EsqueciPasswordService
     */
    var EsqueciPasswordService = (function(m, require){
        var init = function() {
            var SERVICE_URL = '/usuario/%s/%s/%s/reset-password';

            /**
             * @type {BaseService}
             */
            var baseService = new (require('apps/common/service/base-service'))(SERVICE_URL);

            /**
             * @param {String} cpf
             * @param {String} email
             * @param {String} siteKey
             * @returns {Promise<any>}
             */
            this.execute = function(cpf, email, siteKey) {
                return baseService
                    .replacePath(cpf, email, siteKey)
                    .getData(null);
            };
        };

        return init;
    })(m, require);

    module.exports = EsqueciPasswordService;