    /**
     * @class EsqueciPasswordController
     */
    var EsqueciPasswordController = (function(m, require){
        /**
         * @type {EsqueciPasswordService}
         */
        var esqueciPasswordService = new (require('apps/login/modules/esqueci-password/service-esqueci-password'))();

        /**
         * @type {ApiSiteKeyService}
         */
        var apiSiteKeyService = new (require('apps/common/service/service-api-site-key'))();

        /**
         * @type {SpinningButtonModel}
         */
        var spinningButtonModel = new (require('apps/common/viewmodules/spinning-button/model-spinning-button'))();

        var esqueciPasswordModelClass = require('apps/login/modules/esqueci-password/model-esqueci-password');

        var spinningButtonModule = require('apps/common/viewmodules/spinning-button/module-spinnig-button');

        var init = function(args) {
            var ctrl = this;
            this.error = m.prop(null);
            this.sucesso = m.prop(null);
            var captchaRenderizado = false;

            /**
             * @type {EsqueciPasswordModel}
             */
            this.esqueciPasswordModel = (function() {
                var model = null;
                if (args && args.esqueciPasswordModel) {
                    model = args.esqueciPasswordModel;
                } else {
                    model = new esqueciPasswordModelClass();
                }
                apiSiteKeyService.execute().then(function(data) {
                    model.siteKey(data.siteKey);
                });
                return model;
            })();

            /**
             * @param {Object} data
             */
            var erro = function(msg) {
                ctrl.error(msg);
                spinningButtonModel.spin = false;
                m.redraw(true);
            };

            /**
             * @param {String} msg
             * @param {String} classe
             * @param {String} faIcon
             * @returns {*}
             */
            var renderMensagem = function(msg, classe, faIcon){
                return m('p.' + classe, [m('i.fa.' + faIcon, ''), msg]);
            };

            /**
             * @returns {boolean}
             */
            var submeteValido = function() {
                spinningButtonModel.spin = true;
                var email = ctrl.esqueciPasswordModel.email();
                var cpf = ctrl.esqueciPasswordModel.cpf();
                var siteKey = captchaRenderizado && grecaptcha.getResponse() != '' ? grecaptcha.getResponse() : '0';
                if (validaCpf(cpf) && validaEmail(email)) {
                    esqueciPasswordService.execute(cpf, email, siteKey).then(sucesso, erro);
                } else {
                    spinningButtonModel.spin = false;
                }
                return false;
            };

            /**
             * @param {Object} data
             */
            var sucesso = function(data) {
                var value = null;
                if (data['error'] !== undefined) {
                    value = erro(data['error']);
                } else if(data['max-tentativas'] !== undefined){
                    renderCaptcha();
                    value =  erro(data['max-tentativas']);
                } else {
                    ctrl.sucesso('sucesso');
                };
                spinningButtonModel.spin = false;
                m.redraw(true);
                return value;
            };

            /**
             * @param {String} cpf
             * @returns {boolean}
             */
            var validaCpf = function (cpf) {
                cpf = cpf || '';
                ctrl.error(null);
                if (cpf.match(/^\d{11}$/) === null) {
                    ctrl.error('CPF inválido.');
                    return false;
                }
                return true;
            };

            /**
             * @param {String} mail
             * @returns {boolean}
             */
            var validaEmail = function(mail) {
                mail = mail || '';
                ctrl.error(null);
                if (mail.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i) === null) {
                    ctrl.error('Email com formato errado');
                    return false;
                }
                return true;
            };

            /**
             * Retorna para a página de login
             */
            this.goHome = function() {
                m.route('/editar');
            };

            /**
             * @returns {Mithril.Child}
             */
            var renderCaptcha = function() {
                var siteKey = ctrl.esqueciPasswordModel.siteKey();
                if (!captchaRenderizado && (siteKey != null && siteKey != '')) {
                    captchaRenderizado = true;
                    /**
                     * ID da div onde vai ser rederizado o captcha: tentativas-email
                     * @see view-esquecer-password.js
                     */
                    grecaptcha.render('tentativas-email', {'sitekey' : siteKey});
                } else {
                    grecaptcha.reset();
                }
            };

            /**
             * Mensagem de email errado
             * @returns {VirtualElement}
             */
            this.renderEmailErrado = function() {
                return (ctrl.error()) ?
                    renderMensagem(ctrl.error(), 'erro', 'fa-exclamation-triangle') :
                    m('')
            };

            this.renderSucesso = function() {
                return (ctrl.sucesso()) ?
                    renderMensagem('E-mail enviado com sucesso.', 'info', 'fa-exclamation-triangle') :
                    m('')
            };
            //As informações de redefinição de senha foram enviadas no e-mail informado!

            /**
             * @returns {Mithril.Component<T>}
             */
            this.renderSpinningButtonComponnent = function() {
                spinningButtonModel.onsend = submeteValido;
                spinningButtonModel.name = 'Enviar';
                return m.component(spinningButtonModule, spinningButtonModel);
            }
        };

        return init;
    })(m, require);

    module.exports = EsqueciPasswordController;