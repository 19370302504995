    var LoginController = (function(m){
        var init = function(args) {
            var loginData = window.formLoginData;

            var loginErro = function() {
                return loginData.error.length > 0;
            };

            var senhaAlterada = function() {
                return loginData.senhaAlterada.length > 0;
            };

            var saiuDoSistema = function() {
                return loginData.sair.length > 0;
            };

            var sessaoExpirou = function() {
                return loginData.sessao.length > 0;
            };

            var captchaSiteKey = function() {
                return loginData.siteKey;
            };

            this.revisionHash = function() {
                return loginData.revisao;
            };

            this.buildDate = function() {
                return loginData.revisaoData;
            };

            this.csrfValue = function () {
                return loginData._csrf;
            };

            this.formAction = function() {
                return loginData.action;
            };

            var renderMensagem = function(msg, classe, faIcon){
                return m('p.' + classe, [m('i.fa.' + faIcon, ''), msg]);
            };

            this.renderCaptcha = function() {
                var siteKey = captchaSiteKey();
                return (siteKey) ? m('p', [m('div.g-recaptcha', {'data-sitekey':siteKey})]): m('');
            };

            this.renderSessaoExpirou = function() {
                return (sessaoExpirou()) ? renderMensagem('Sua sessão expirou!', 'info', 'fa-info') : m('');
            };

            this.renderSaiuDoSistema = function() {
                return (saiuDoSistema()) ? renderMensagem('Você saiu do sistema.', 'info', 'fa-info') : m('');
            };

            this.renderSenhaAlterada = function() {
                return (senhaAlterada()) ? renderMensagem('Senha alterada com sucesso.', 'info', 'fa-info') : m('');
            };

            this.renderLoginErro = function() {
                return (loginErro()) ?
                    renderMensagem('Seu CPF e/ou sua senha estão incorretos.', 'erro', 'fa-exclamation-triangle') :
                    m('');
            }
        };

        return init;
    })(m);

    module.exports = LoginController;