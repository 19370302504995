var LoginView = (function(m){
    /**
     * @param {LoginController} ctrl
     * @param args
     */
    var init = function(ctrl) {
        return m('div', [
            m('p.cabecalho-cor'),
            m('div#wrapper', [
                m('header', [
                    m('div', [
                        m('a', {href:'/editar'}, [m('h1', 'Editor de Serviços')])
                    ])
                ]),
                m('form#login', {action:ctrl.formAction(), method:'post'}, [
                    m('h2', 'Acesso para Servidores Públicos'),
                    ctrl.renderLoginErro(),
                    ctrl.renderSenhaAlterada(),
                    ctrl.renderSaiuDoSistema(),
                    ctrl.renderSessaoExpirou(),
                    m('div.borda', [
                        m('label', {'for':'usuario'}, 'CPF'),
                        m('input#usuario', {name:'username', type:'text', placeholder:'XXX.XXX.XXX-XX', required:'required', autofocus:'true'}),
                        m('label', {'for':'senha'}, 'Senha'),
                        m('input#senha', {name:'password', type:'password', placeholder:'Senha', required:'required'}),
                        ctrl.renderCaptcha(),
                        m('p#esqueci-password'),
                        m('p.tooltip-home', [
                            m('a[href="/editar/esqueci-password"]', {config: m.route}, 'Esqueceu sua senha? Clique aqui!'),
                            m('span', ' Clique para redefinir a sua senha! Caso você não tenha cadastro, entre em contato com o responsável (ponto focal) do seu órgão.')
                        ]),
                        m('button', {type:'submit'}, 'Entrar')
                    ]),
                    m('p', {style:'font-size: 0.6em'}, [
                        m('span', ctrl.revisionHash()),
                        m('span', ctrl.buildDate())
                    ]),
                    m('input', {type:'hidden', name:'_csrf', value:ctrl.csrfValue()})
                ])
            ])
        ]);
    };

    return init;
})(m);

module.exports = LoginView;