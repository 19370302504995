    /**
     * @class EsqueciPasswordModel
     */
    var EsqueciPasswordModel = (function(m){
        var init = function(data) {
            data = data || {};
            this.cpf = m.prop(_.trim(data.cpf) || null);
            this.email = m.prop(_.trim(data.email) || null);
            this.siteKey = m.prop(_.trim(data.siteKey) || null);
        };

        return init;
    })(m);

    module.exports = EsqueciPasswordModel;