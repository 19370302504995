    var Routes = (function(body) {
        var init = function(m) {
            m.route.mode = 'pathname';
            m.route(body, '/editar', {
                '/editar'                  : require('apps/login/modules/login/module-login'),
                '/editar/esqueci-password' : require('apps/login/modules/esqueci-password/module-esqueci-password')
            });
        };

        return init;
    })(document.body);

    module.exports = Routes;
